exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-club-page-js": () => import("./../../../src/templates/club-page.js" /* webpackChunkName: "component---src-templates-club-page-js" */),
  "component---src-templates-club-team-page-js": () => import("./../../../src/templates/club-team-page.js" /* webpackChunkName: "component---src-templates-club-team-page-js" */),
  "component---src-templates-gdpr-page-js": () => import("./../../../src/templates/gdpr-page.js" /* webpackChunkName: "component---src-templates-gdpr-page-js" */),
  "component---src-templates-impress-page-js": () => import("./../../../src/templates/impress-page.js" /* webpackChunkName: "component---src-templates-impress-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-info-page-js": () => import("./../../../src/templates/info-page.js" /* webpackChunkName: "component---src-templates-info-page-js" */),
  "component---src-templates-infos-page-js": () => import("./../../../src/templates/infos-page.js" /* webpackChunkName: "component---src-templates-infos-page-js" */),
  "component---src-templates-member-page-js": () => import("./../../../src/templates/member-page.js" /* webpackChunkName: "component---src-templates-member-page-js" */)
}

