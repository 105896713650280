import * as React from "react"
import { Helmet } from "react-helmet"
import Navbar from "./Navbar"
import Footer from "./Footer"
import useSiteMetadata from "../hooks/useSiteMetadata"
import { useScroll } from "../hooks/useScroll"

import ogimage from "../../static/media/ogimage.jpg"

// import { Script } from "gatsby"
// <Script id="sc-dark-mode">{`document.documentElement.classList.toggle(  "dark",  localStorage.theme === "dark" ||    (!("theme" in localStorage) && window.matchMedia("(prefers-color-scheme: dark)").matches),);`}</Script>
// <Script id="sc-dark-mode2">{`localStorage.theme = "dark";`}</Script>

const Layout = ({ location, children }) => {
  // set global title and description on global layout, can be overwritten on a per-page basis with MyHelmet
  const { title, description, siteUrl, sitePrefix } = useSiteMetadata()
  const { scrollY, scrollDirection } = useScroll()

  return (
    <div className="bg-white dark:bg-gray-700">
      <Helmet>
        <html lang="de" prefix="og: http://ogp.me/ns#" />
        <title>{title} | TC Freinsheim</title>
        <meta name="description" content={description} />
        <meta
          name="theme-color"
          content="#374151"
          media="(prefers-color-scheme: dark)"
        />
        <meta name="theme-color" content="#15803d" />
        <meta property="og:title" content={`${title}`} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={`${siteUrl}${location.pathname}`} />
        <meta
          property="og:image"
          content={`${siteUrl}${sitePrefix}${ogimage}`}
        />
        <meta property="og:image:width" content="1229" />
        <meta property="og:image:height" content="819" />
        <meta property="og:locale" content="de_DE" />
        <meta property="og:site_name" content="Tennisclub Freinsheim" />
      </Helmet>
      <div className="flex flex-col h-screen justify-between">
        <Navbar
          className={`transition transform duration-300 ease-in-out ${
            scrollDirection === "down" || scrollY < 200
              ? ""
              : "-translate-y-full"
          }`}
        />
        {/* Navbar height will be h-12 sm:h-14 md:h-18 */}
        <main className="mb-auto bg-white dark:bg-gray-700">{children}</main>
        <Footer />
      </div>

      {/* small badge to display current break-point (only in dev mode) */}
      {process.env.NODE_ENV === "development" ? (
        <div className="fixed right-4 bottom-4">
          <div className="inline-block px-2 py-0.5 rounded-full text-xs shadow text-gray-600 bg-white/80">
            <div className="sm:hidden">xs</div>
            <div className="hidden sm:inline md:hidden">sm</div>
            <div className="hidden md:inline lg:hidden">md</div>
            <div className="hidden lg:inline xl:hidden">lg</div>
            <div className="hidden xl:inline 2xl:hidden">xl</div>
            <div className="hidden 2xl:inline">2xl</div>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default Layout
